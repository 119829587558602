import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { defineRule } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import VueFeather from 'vue-feather';
import staticVars from './staticVars';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia'
// import Datepicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
//import Datepicker from 'vue3-datepicker';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import vClickOutside from "click-outside-vue3"
Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});

//Locales
import en from '../public/assets/locales/en.js'
//Internationalization
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  warnHtmlMessage: false,
  messages: {
    en
  },
})
//CSS imports
import "../public/assets/css/normalize.css"
import "../public/assets/css/flexslider.css"
import "../public/assets/css/animate.css"
import "../public/assets/css/flags.css"
import "../public/assets/css/style.css"

const app = createApp(App)

const pinia = createPinia()
app.config.globalProperties.static_vars = staticVars

app.component(VueFeather.name, VueFeather)
app.component('VueDatePicker', VueDatePicker)
//app.component('datepicker', Datepicker)
app.use(vClickOutside)

app.use(i18n).use(store).use(pinia).use(router).mount('#app')
